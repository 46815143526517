import { defineStore } from "pinia"
import { useProjectStore } from "@/stores/projects"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useUserStore } from "@/stores/users"
import { useRouterStore } from "@/stores/router"

export const useGlobalDialogStore = defineStore({
  id: "globalDialogs",
  actions: {
    closeDialogs(shouldClearHistory = true) {
      const projectStore = useProjectStore()
      const cameraDialogStore = useCameraDialogStore()
      const userStore = useUserStore()
      const routerStore = useRouterStore()

      if (shouldClearHistory) {
        projectStore.closeDialog()
        cameraDialogStore.closeDialog()
        userStore.closeDialog()
        routerStore.closeDialog()
      } else {
        projectStore.dialog = false
        cameraDialogStore.dialog = false
        userStore.dialog = false
        routerStore.dialog = false
      }
    },
  },
})
