import { defineStore } from "pinia"
import { updateSubQuery, clearSubQuery } from "@evercam/shared/utils"
import { Router } from "@evercam/shared/types"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { useGlobalDialogStore } from "@/stores/globalDialogs"

type RouterState = {
  dialog: boolean
  selectedRouter: Router
}

export const useRouterStore = defineStore({
  id: "router",
  state: (): RouterState => ({
    dialog: false,
    selectedRouter: null as Router,
  }),
  actions: {
    async openDialog(router, shouldUpdateQuery = true) {
      const serialNumber = router?.serialNumber || router
      await this.selectRouter(serialNumber)

      if (!this.selectedRouter || this.dialog) {
        return
      }
      useGlobalDialogStore().closeDialogs(false)
      this.dialog = true

      if (shouldUpdateQuery) {
        updateSubQuery({
          subQueryObj: { routerDialog: true, serialNumber },
          overridePreviousQuery: true,
          pushToHistory: true,
        })
      }
    },
    closeDialog() {
      if (!this.dialog) {
        return
      }

      clearSubQuery({ pushToHistory: true })
      this.$reset()
    },
    async selectRouter(serialNumber) {
      if (!serialNumber) {
        return
      }
      const { items } = await AdminApi.routers.getRouters({
        params: {
          sort: "inserted_at|desc",
          limit: 1,
          page: 1,
          serialNumber,
        },
      })

      this.selectedRouter = items?.find(
        (item) => item.serialNumber == serialNumber
      )
    },
  },
})
